.fuse-horizontal-navigation-menu-item .mdc-list-item__primary-text {
  width: 100%;
}

fuse-vertical-navigation
  fuse-vertical-navigation-collapsable-item
  .fuse-vertical-navigation-item-children
  .fuse-vertical-navigation-item {
  color: #fff !important;
}

a.fuse-vertical-navigation-item {
  color: #fff !important;
}
