.selected-row {
  background-color: rgba(var(--fuse-bg-hover-rgb), 0.4) !important;
}

.selected-table-row {
  td {
    position: relative;
    background-color: rgba(var(--fuse-bg-hover-rgb), 0.4) !important;
  }

  td:first-child::after {
    content: ' ';
    background-color: rgba(var(--fuse-primary-rgb), 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
  }
}

.dark {
  .selected-row {
    background-color: rgba(var(--fuse-bg-hover-rgb), 0.025) !important;
  }
  .selected-table-row {
    td {
      position: relative;
      background-color: rgba(var(--fuse-bg-hover-rgb), 0.025) !important;
    }
  }
}

.sbd-table-resizable.mat-mdc-table {
  width: 100%;

  @screen md {
    table-layout: fixed !important;
  }

  .mdc-data-table__header-row {
    height: 32px;
  }

  &.resizing {
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: col-resize;
  }

  .mat-mdc-cell {
    span.resize-holder {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .mat-mdc-header-cell {
    position: relative;

    &[ng-reflect-resizable='true'] {
      cursor: pointer;
    }

    &:not(:last-child) {
      .resize-holder {
        cursor: col-resize;
        width: 20px;
        height: 100%;
        position: absolute;
        right: -10px;
        top: 0;
        z-index: 1;
      }
    }
  }
  .mat-mdc-cell,
  .mat-mdc-header-cell {
    border-right: 1px solid var(--fuse-border) !important;
    // &:not(:nth-child(1)) {
    //   padding: 0 10px;
    // }

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 16px;
  }
}

.doug-table.mat-mdc-table {
  @apply w-full;

  @screen sm {
    // table-layout: fixed !important;
  }

  .mdc-data-table__header-row {
    @apply h-[32px];
  }

  .mat-mdc-cell {
    @apply leading-[18px];
  }
  // .mat-mdc-cell,
  // .mat-mdc-header-cell {
  //   @apply whitespace-nowrap overflow-hidden text-ellipsis leading-tight;
  // }
}
