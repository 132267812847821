:root {
  --sbd-brand-green: #56c271;
  --sbd-brand-orange: #fd7f13;
  --sbd-brand-orange-dark: #BC631AFF;
  --sbd-brand-blue: #027398;
  --delete-color: #fa384b;
  --white: #fff;
  --black: #000;
}

@import './styles/overrides/angular-material.scss';
@import './styles/overrides/fuse.scss';
@import './styles/overrides/ngx-timepicker.scss';
@import './styles/cathy.scss';
@import './styles/buttons.scss';
@import './styles/tables.scss';

mat-radio-button {
  &.reversed-label{
    display: flex;
    .mdc-form-field {
      display: flex;
      width: 100%;

      .mdc-label {
        width: 100%;
        cursor: pointer;
      }
    }

  }
}


.bg-renewal {
  background-color: #5bc0de;
  @apply font-bold px-1.5 rounded-sm ml-0.5 text-white #{!important};
}

.rendered-text {
  h1 {
    @apply text-2xl font-bold;
  }
  h2 {
    @apply text-xl font-bold;
  }
  h3 {
    @apply text-lg;
  }
  ul {
    @apply list-disc ml-6;
  }
  a {
    @apply text-primary;
  }
  a:hover {
    @apply underline cursor-pointer;
  }
  strong {
    @apply font-semibold;
  }
  em {
    @apply italic;
  }
}

.initials-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
}


.accounting-format {
  text-align: right !important;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.selected-table-row {
  td {
    position: relative;
    background-color: rgba(var(--fuse-bg-hover-rgb), 0.4) !important;
  }

  td:first-child::after {
    content: ' ';
    background-color: rgba(var(--fuse-primary-rgb), 1);
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
  }
}


.dark {
  .selected-row {
    background-color: rgba(var(--fuse-bg-hover-rgb), 0.025) !important;
  }
  .selected-table-row {
    td {
      position: relative;
      background-color: rgba(var(--fuse-bg-hover-rgb), 0.025) !important;
    }
  }
}

.fullscreen-dialog {
  max-width: 100% !important;
  max-height: 100% !important;
  width: 100%;
  height: 100%;
}
