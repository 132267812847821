mat-form-field.hide-subscript-wrapper .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.mat-drawer-backdrop {
  display: none !important;
}

.theme-brand .dark .mat-mdc-unelevated-button.mat-primary, .theme-brand.dark .mat-mdc-unelevated-button.mat-primary,
.theme-brand .light .mat-mdc-unelevated-button.mat-primary, .theme-brand.light .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-label-text-color: #000000;
}


.mat-mdc-paginator-range-label {
  @apply hidden;
}

@screen sm {
  .mat-mdc-paginator-range-label {
    @apply inline-block;
  }
}

.mat-mdc-form-field.mat-form-field-appearance-fill .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
  @apply min-h-[40px];
}

.ngx-mat-timepicker-field {
  position: relative;
  display: flex;
  width: 500px !important;
  height: 30px;
  padding: 0 5px;
  box-sizing: border-box;
}

.mat-mdc-paginator {
  .mat-mdc-paginator-container {
    padding: 0 16px;
  }
}
