.success-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--sbd-brand-green);
    --mdc-snackbar-supporting-text-color: var(--black);
  }
}

.error-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--delete-color);
    --mdc-snackbar-supporting-text-color: var(--white);
  }
}

.info-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--sbd-brand-blue);
    --mdc-snackbar-supporting-text-color: var(--white);
  }
}

.warning-snackbar {
  .mdc-snackbar__surface {
    --mdc-snackbar-container-color: var(--sbd-brand-orange);
    --mdc-snackbar-supporting-text-color: var(--white);
  }
}
