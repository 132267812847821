.mdc-button:active,
.mdc-button:focus {
  outline: 2px solid #027398 !important;
  outline-offset: 0px;
}

.dark {
  .mdc-button:active,
  .mdc-button:focus {
    outline: 2px solid #8bff76 !important;
  }
}

.round-icon-button {
  @apply flex justify-center items-center w-10 h-10 my-1 bg-primary rounded-full sm:my-0;

  &.btn-sm {
    @apply w-6 h-6;

    mat-icon {
      @apply w-4 h-4 min-w-4 max-w-4;
    }
  }

  &:active,
  &:focus &:hover {
    outline: 2px solid #027398 !important;
    outline-offset: 0px;
  }
  mat-icon {
    @apply text-black;
  }
}

.dark {
  .round-icon-button {
    &:active,
    &:focus &:hover {
      outline: 2px solid #8bff76 !important;
    }
  }
}

.sbd-btn-secondary {
  @apply bg-brandBlue-500 text-white  #{!important};
}
