@media screen and (max-width: 445px) {
  ngx-mat-timepicker-face .clock-face {
    width: 200px !important;
    height: 200px !important;
  }
  ngx-mat-timepicker-face .clock-face__number {
    transform-origin: 25px 85% !important;
    top: calc(50% - 104px) !important;
  }
  ngx-mat-timepicker-face .clock-face__clock-hand_minute > button {
    top: -3px !important;
  }
  .mdc-fab--mini {
    height: 40px !important;
    width: 40px !important;
  }
  .mat-mdc-dialog-container .mdc-dialog__surface {
    width: 105% !important;
  }
  div.ngx-mat-timepicker-dialog>mat-dialog-container [mat-dialog-content] {
    padding: 10px !important;
  }
}

